
import Navbar from './components/navbar';
import Footer from './components/footer';
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/home';
import Faq from './pages/faq';
import Gallery from './pages/gallery';
import Registration from './pages/registration';
import Rules from './pages/rules';
import Food from './pages/food';
import Trivia from './pages/trivia';
import Landing from './pages/landing';
import Links from './pages/links';



const App = () => {
  return (

    <Router>

      <body className='body'>
        
        <div className='content'>
        <Navbar />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/Faq" element={<Faq />} />
            <Route path="/rules" element={<Rules />} />
            <Route path="/food" element={<Food />} />
            <Route path="/trivia" element={<Trivia />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/links" element={<Links />} />

          </Routes>
        </div>
        
      </body>
      <Footer />
    </Router>

  );
};

export default App;

/*

    <Route path="/faq" element={<Faq />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/registration" element={<Registration />} />

            */