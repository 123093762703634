import React from 'react'
import './links.css';

export default function Links() {
    return (

        <div className='linkList'>

            <h1>Useful links</h1>

            <button className='link'><a className='hyperlink' href='https://www.facebook.com/profile.php?id=61551455224229'>Facebook</a></button>

            <button className='link'><a className='hyperlink' href='https://www.instagram.com/dakimakurainc/'>Instagram</a></button>


        </div>
    )
}