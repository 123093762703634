import React from 'react';
import './footer.css'


function Footer() {

    return(
    <div className='footer'>


<p>{new Date().getFullYear()} Dakimakura Inc. official website. All rights reserved. Contact us <a>dakimakurainc@gmail.com</a></p>


    </div>
    );
}

export default Footer