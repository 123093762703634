import React from 'react';
import Logo from '../assets/header-web.png';
import { BrowserRouter as Router, Routes, Link, Route } from 'react-router-dom';
import './navbar.css';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
//import 'bootstrap/dist/css/bootstrap.css';

function Navbar() {


    return (

        <div className='navbar'>
            <div className='banner'>
                <img src={Logo} alt='dakiLogo' className='dakiLogo' />
            </div>
            <div className='links'>
                <nav>
                    <button className='buttonStyle'><Link to="/" className='linkButton'>Home </Link></button>

                    <button className='buttonStyle'> <Link to="/links" className='linkButton'>Lynx</Link></button>

                    <button className='buttonStyle'><Link to="/faq" className='linkButton'>FAQ</Link></button>
                </nav>
            </div>
        </div>
    );
}

export default Navbar

/*   <button className='buttonStyle'> <Link to="/" className='linkButton'>Home</Link></button>

                    <button className='buttonStyle'> <Link to="/registration" className='linkButton'>Registration</Link></button>
                    <button className='buttonStyle'>
                        <Dropdown data-bs-theme="dark">
                            <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                Open Menu
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#">
                                    Home Page
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                    Settings
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                    Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </button>
*/
/*    <DropdownButton id="dropdown-custom-1" title="Dropdown button">
                          <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                          <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                          <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                      </DropdownButton> */
/*


            <button className='buttonStyle'> <Link to="/food" className='linkButton'>Food?</Link></button>  

                <button className='buttonStyle'> <Link to="/trivia" className='linkButton'>Trivia</Link></button>

                <button className='buttonStyle'> <Link to="/rules" className='linkButton'>Beyblade</Link></button>
                    
                <button className='buttonStyle'><Link to="/" className='linkButton'>Home </Link></button> 




<button className='buttonStyle'><Link to="/gallery" className='linkButton'>Gallery </Link></button>

                    
            */